// top page js

toppage();

function toppage(){
  if (!$('.home').length) return;

  loaded();
  conveyorBelt();
}



function loaded(){
  $(window).on('load',function(){
    $('.goodwill').addClass('-show');
    movieSet();
  });
}


function movieSet(){
  $('.movieSet').each(function(){
    var $this = $(this);
    var type = $this.attr('data-type');
    var movie = $this.attr('data-movie');
    if (windowWidth > windowSm) {
      if (type == 'pc') {
        _put();
      }else{
        $this.remove();
      }
    } else {
      if (type == 'sp') {
        _put();
      }else{
        $this.remove();
      }
    }
    function _put(){
      $this.html('\
        <video autoplay="" loop="" playsinline="" muted="">\
          <source src="'+movie+'">\
        </video>\
      ');
    }
  });
}


function conveyorBelt(){
  $(window).on('load', function(){
    var belt = '.unit-sns__belt';
    var original = belt+'[data-belt="original"]';
    var clone = belt+'[data-belt="clone"]';
    var item = $(original).find('.unit-sns__belt__item');
    var itemW = item.width();
    var itemHtml = $(belt).html();
    var dW = window.innerWidth;

    var pushHtml = '';

    var rates = (dW*2 / itemW);
    rates = Math.ceil(rates);

    if (rates >= 2) {
      var cnt = rates;
      for (var i = 0; i < cnt+1; i++) {
        pushHtml += itemHtml;
      }
    }else{
      for (var i = 0; i < 2; i++) {
        pushHtml += itemHtml;
      }
    }

    $(belt).each(function(){
      $(this).html(pushHtml);
    });
  });
}