// common js

const windowSm = 750;
var windowWidth = window.innerWidth;

// if (windowWidth > windowSm) {
// } else {
// }
window.onpageshow = function(event) {
  if (event.persisted) {
    window.location.reload();
  }
};

$(window).on('load', function(){
  $('body').addClass('-loaded');
});



common();

function common(){
  timesLimited();
  bnrFixed();
  screenTransition();
  smoothScroll();
  headLinks();
  movieThumb();
  movieModal();
  headerType();
  busButton();
  movieSlider();
  photoSlider();
  menuMore();
  newsMore();
  spMenu();
  contact_reCAPCHA();
}



function timesLimited(){
  var now = new Date();
  $('.timesItem').each(function(){
    var start = new Date($(this).attr('data-start'));
    var end = new Date($(this).attr('data-end'));
    if( start < now && now < end ){
      $(this).css({
        'display' : '',
      });
    }else{
      $(this).remove();
    }
  });
}
function bnrFixed(){
  $('.bnr-fixed__close').click(function(){
    $(this).parents('.bnr-fixed').fadeOut();
  });
}


function screenTransition(){
  $('a:not([href*="#"]):not([target])').on('click', function(e){
    e.preventDefault();

    var item = $('.item-loading__text li');
    var random = Math.floor( Math.random() * item.length );
    item.css('display', 'none');
    item.eq(random).css('display', 'block');
    $.cookie('loadingtext', item.eq(random).text(), { path: '/' });
    url = $(this).attr('href');
    if (url !== '') {
      $('body').removeClass('-loaded');
      setTimeout(function(){
        window.location = url;
      }, 400);
    }
    return false;
  });
}


function smoothScroll(){
  $(document).on('click', 'a[href*="#"]' ,function(){
    var href = $(this).attr('href');
    href = href.split('#')[1];
    console.log(href);
    var target = $('#'+href);
    var headspace = $('.l-header').height() + 100;
    if (target.length) {
      var position = target.offset().top - headspace;
      $("html, body").animate({scrollTop:position}, 700, "swing");
      return false;
    }
  });
}


function headLinks(){
  var link = '.head-links__parent';
  var target = '.head-links__children';
  $(document).on({
    'mouseenter': function(){
      _open($(this).siblings(target));
    },
    'mouseleave': function(){
      _close($(this).siblings(target));
    }
  }, link);

  $(document).on({
    'mouseenter': function(){
      _open($(this));
    },
    'mouseleave': function(){
      _close($(this));
    }
  }, target);

  function _open(item){
    item.stop().fadeIn();
  }
  function _close(item){
    item.stop().fadeOut();
  }
}

function movieThumb(){
  var link = '.linkMovie';
  $(link).each(function(){
    var movie = $(this).attr('data-url');
    var y_id = movie.match(/[\/?=]([a-zA-Z0-9_\-]{11})[&\?]?/)[1];
    var img = 'https://img.youtube.com/vi/'+y_id+'/maxresdefault.jpg';
    $(this).find('figure').css({
      'background-image': 'url('+img+')',
    });
  });
}

function movieModal(){
  var link = '.linkMovie';
  var scroll;
  var movie;
  var y_id;
  var links;
  var bg = '<div class="screen-overlay"></div>';

  if(!$('.screen-overlay').length){
    $('.l-wrapper').append(bg);
  }

  $(document).on('click', link, function(){
    movie = $(this).attr('data-url');
    y_id = movie.match(/[\/?=]([a-zA-Z0-9_\-]{11})[&\?]?/)[1];
    links = $(this).data('links');
    var linksHtml = '';
    if (links[0] != '') {
      if (links[2]) {
        var blank = 'target="_blank"';
      }else{
        var blank = '';
      }
      linksHtml = '\
        <a href="'+links[1]+'" '+blank+'>\
          '+links[0]+'\
        </a>\
      ';
    }
    $('.unit-modal').remove();
    var modalHtml = '\
      <div class="unit-modal">\
        <div class="unit-modal__close"><span></span></div>\
        <iframe src="https://www.youtube.com/embed/'+y_id+'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>\
        '+linksHtml+'\
      </div>';
    if(!$('.unit-modal').length){
      _open(modalHtml);
    }else{
      _close();
    }
  });
  $(document).on('click', '.unit-modal__close, .screen-overlay', function(){
    _close();
  });

  function _open(modalHtml){
    $('.l-wrapper').append(modalHtml);
    $('.screen-overlay').fadeIn(700);
    $('.unit-modal').fadeIn(700);
  }
  function _close(){
    $('.screen-overlay').fadeOut(700);
    $.when(
      $('.unit-modal').fadeOut(700).delay(700)
    ).done(function(){
      $('.unit-modal').eq(0).remove();
    });
  }
}


function headerType(){
  var scroll = $(window).scrollTop();
  $(window).scroll(function(){
    scroll = $(window).scrollTop();
    if (scroll > 400) {
      $('.l-header').addClass('-conpact');
    }else{
      $('.l-header').removeClass('-conpact');
    }
  });
}


function busButton(){
  var scroll = $(window).scrollTop();
  var btn = $('.btn-bus');
  var flg = true;
  $(window).scroll(function(){
    var windowWidth = window.innerWidth;
    if (windowWidth <= windowSm) return;

    scroll = $(window).scrollTop();
    if (scroll > 400) {
      if (flg && !btn.hasClass('-small')) {
        flg = false;
        $.when(
          btn.css({
            'transform': 'scale(0,1)',
          }).delay(600)
        ).then(function(){
          btn.addClass('-small');
          btn.toggleClass('-invert');
        }).done(function(){
          btn.css({
            'transform': 'scale(1,1)',
          });
          flg = true;
        });
      }
    }else{
      if (flg && btn.hasClass('-small')) {
        flg = false;
        $.when(
          btn.css({
            'transform': 'scale(0,1)',
          }).delay(600)
        ).then(function(){
          btn.removeClass('-small');
          btn.toggleClass('-invert');
        }).done(function(){
          btn.css({
            'transform': 'scale(1,1)',
          });
          flg = true;
        });
      }
    }
  });
  setInterval(function(){
    btn.toggleClass('-invert');
  },8000);
}


function movieSlider(){
  var mql = window.matchMedia('screen and (max-width: 750px)');
  function checkBreakPoint(mql) {
    if (mql.matches) {
      // SP
      $('.lead-ecsite__channel__list').slick({
        'prevArrow': '<div class="lead-ecsite__arrow -prev"></div>',
        'nextArrow': '<div class="lead-ecsite__arrow -next"></div>',
      });
    } else {
      // PC
      $('.lead-ecsite__channel__list.slick-initialized').slick('unslick');
    }
  }

  mql.addListener(checkBreakPoint);
  checkBreakPoint(mql);
}



function photoSlider(){
  $('.slider-photo.-main').each(function(){
    var main = $(this);
    var sub = $(this).siblings('.-sub');
    main.slick({
      'fade': true,
      'prevArrow': '<div class="-prev"></div>',
      'nextArrow': '<div class="-next"></div>',
      'asNavFor': sub,
      'autoplay': true,
    });
    sub.slick({
      'slidesToShow': 3,
      'arrows': false,
      'dots': true,
      'asNavFor': main,
      'focusOnSelect': true,
    });
  });
}

function menuMore(){
  $('.box-menu__more').click(function(){
    var content = $(this).parents('.box-menu').find('.box-menu__content');
    var $text = $(this).find('p');
    var text_base = $text.text();
    var text_close = text_base.replace('もっと見る', '閉じる');
    var text_more = text_base.replace('閉じる', 'もっと見る');
    if ($(this).hasClass('-active')) {
      content.css({
        'height': '',
      });
      $(this).removeClass('-active');
      $text.text(text_more);
    }else{
      content.css({
        'height': 'auto',
      });
      $(this).addClass('-active');
      $text.text(text_close);
    }
  });
}

function newsMore(){
  $('.item-news__more').click(function(){
    var content = $(this).parents('.item-news').find('.item-news__content');
    var $text = $(this);
    var text_base = $text.text();
    var text_close = text_base.replace('続きを読む', '閉じる');
    var text_more = text_base.replace('閉じる', '続きを読む');
    if ($(this).hasClass('-active')) {
      content.css({
        'height': '',
      });
      $(this).removeClass('-active');
      $text.text(text_more);
    }else{
      content.css({
        'height': 'auto',
      });
      $(this).addClass('-active');
      $text.text(text_close);
    }
  });
}

function spMenu(){
  var scroll;
  $('.btn-menu').click(function(){
    if ($(this).hasClass('-active')) {
      $('body').css({
        'position': 'static',
        'top': 'auto',
      });
      $(window).scrollTop(scroll);
      $(this).removeClass('-active');
      $('.link-nav').stop().fadeOut();
    }else{
      scroll = $(window).scrollTop();
      $('body').css({
        'position': 'fixed',
        'left': 0,
        'right': 0,
        'top': '-'+scroll+'px',
      });
      $(this).addClass('-active');
      $('.link-nav').stop().fadeIn();
    }
  });

  $('.link-nav__btn').click(function(){
    var parent = $(this).parents('.link-nav__parent');
    var children = parent.siblings('.link-nav__children');
    $(this).toggleClass('-active');
    children.stop().slideToggle();
  });

  // sp hidden
  var scroll_val = 0;
  var pre_pos = 0;
  var pre_status;
  var $items = $('.l-header, .btn-menu');
  $(window).scroll(function(){
    scroll_val = $(window).scrollTop();
    if (scroll_val > 0) {
      if (windowWidth <= windowSm) {
        var now_pos = $(window).scrollTop();
        if(pre_pos == now_pos){
          if(pre_status == true){
            _upward();
          }else{
            _downward();
          }
        }else if(pre_pos > now_pos){
          _upward();
          pre_status = true;
        }else{
          _downward();
          pre_status = false;
        }
        pre_pos = now_pos;
      }
    }
  });
  function _upward(){
    $items.removeClass('-sphidden');
  }
  function _downward(){
    $items.addClass('-sphidden');
  }
}




function contact_reCAPCHA(){
  if (!$('.form-table').length) return;

  $('.btn-basic p').each(function(){
    if ($(this).text() == '') {
      $(this).remove();
    }else{
      $(this).find('*').eq(0).unwrap();
    }
  });
  $('.btn-basic .g-recaptcha').remove();

  // $('.btn-basic [data-callback]').insertBefore($(this).parents('.btn-basic'));
}